@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  * {
    box-sizing: border-box;
    font-family: monospace, ui-sans-serif, system-ui, sans-serif;    
  }

  .loginWrap .p-inputtext {
    font-family: monospace, ui-sans-serif, system-ui, sans-serif !important; 
  }

  html, body {
   @apply h-full;
   
  }

  body {
    background-color: #18191a;    
    color: #fff;    
  }

  body input, body textarea {
    @apply bg-transparent rounded-md border border-gray-700 focus:border-sky-600 focus:outline-none !important;
  }

  body input[type="radio"] {
    @apply rounded-full !important;
  }

  body input::placeholder {
    @apply text-sm text-gray-500
  }

  *:focus {
    box-shadow: none !important;
  }   
}

@layer components {
  
  .navbarWrap .pi-bars {
    @apply text-base !important;
  }

  .menuWrap {
    height: calc(100% - 5rem);    
  } 
  
  .menuWrap .p-panelmenu-panel *{
    background-color: transparent !important;
    /* color: white !important; */
  }

  .menuWrap .p-panelmenu-panel .p-menuitem-text {
    color: #fff !important;
    font-weight: 500;
  }

  .menuWrap  {
    color: #fff !important
  }

  .menuWrap .p-panelmenu-panel {
    @apply m-0 !important;
  }

  .menuWrap .p-panelmenu-header:hover,
  .menuWrap .p-menuitem:hover {
    background-color: black !important;
    transition-duration: .5s;
  }

  .menuWrap .p-component *{
    border-radius: 0 !important;
    border: none !important;
    @apply text-sm font-thin ;
  }   

  /* .menuWrap .p-submenu-list {
    @apply border-l border-white pl-4 !important;
  }   */

  .menuWrap .p-panelmenu-header-link svg{
    @apply absolute right-4 !important;
  }

  .menuWrap .p-menuitem-active {
    @apply text-yellow-300 !important;
  }

  .p-icon {
    position: absolute;
    right: 10px;  
   
  }

  .mainWrap .p-breadcrumb {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
  }

  .mainWrap .p-breadcrumb span {
    color: #cfd3dc !important;
  }

  .mainWrap .p-breadcrumb span:hover {
    @apply text-blue-600 duration-200 !important;
        
  }

  .mainWrap .p-menuitem-separator > svg {
    margin-left: 10px !important;
    color: #cfd3dc !important;
    position: relative !important;
  }

  .tableWrap .p-datatable-tbody {
    @apply pt-10 !important;
  }

  .tableWrap .p-datatable-thead th {
    @apply bg-[#141414] text-gray-400 mb-[-2px] !important;
    padding: 1rem 0.5rem !important;    
  }

  .tableWrap .p-datatable-tbody tr {
    @apply bg-[#101111] text-gray-100 hover:bg-[#222222] rounded-lg focus:outline-none !important;
  }

  .tableWrap .p-datatable-tbody .p-datatable-row-expansion {
    @apply bg-transparent hover:bg-transparent !important;
  }

  .tableWrap table {
    @apply border-separate border-spacing-y-3 text-sm bg-customColor-bgGray !important
  }  
  
  .tableWrap table tr {
    @apply rounded-lg !important;
  }

  .tableWrap .p-datatable-scrollable-header {
    @apply bg-[#141414] !important
  }  

  .tableWrap .p-datatable-thead th,
  .tableWrap .p-datatable .p-datatable-tbody > tr > td {
    @apply border-none !important
  }

  .tableWrap .p-paginator,
  .tableWrap .p-paginator * {
    background-color: transparent !important;
    border: none;
  }    
  
  .tableWrap .p-paginator .p-link,
  .tableWrap .p-paginator span,
  .tableWrap .p-paginator input {
    color: white !important;    
  } 

  .tableWrap .p-paginator .p-highlight {
    @apply bg-transparent text-yellow-400 !important;
  } 

  .purchaseWrap td button {
    @apply text-sm !important;
  }

  .p-dialog .p-dialog-footer {
    background: none !important;
    background-color: red !important;
  }

  .activeBtn button,
  .p-button{
    @apply text-sm my-2 px-3 py-1.5 !important;
  }

  .shareRecord .p-tabmenu-nav {
    @apply border-b !important;
  }

  .shareRecord .p-tabmenu-nav,
  .shareRecord .p-tabmenu-nav *{
    @apply bg-transparent text-sm !important;
  }

  .p-inputswitch .p-inputswitch-slider {
    box-shadow: none !important;
  }

  .flex .p-disabled {
    @apply pointer-events-auto cursor-not-allowed !important;
  }
  
  .navbarWrap .marquee-container {
    @apply w-[100%] max-md:w-[80%] ml-6 !important;    
  }  

  .p-dropdown-item {
    @apply whitespace-normal !important;
  }  

  .custom-dropdown {
    @apply bg-transparent shadow-none border border-gray-700 p-0 rounded-md !important;
  }

  .custom-dropdown .p-dropdown-label {
    @apply text-white p-2 text-sm !important;
  }  

  .custom-dropdown .p-placeholder {
    @apply text-gray-500 !important;
  }  
  
  .p-dropdown-panel input {
    @apply text-black !important;
  }

  .custom-calendar .p-inputtext {
    @apply text-white
  }  

  .contentWrap:after {
    content: "";
    position: absolute;
    width: 10;
    height: 10;
    top: 50%;
    right: 100%;
    margin-top: -9px;
    background-color: white;
  }

  .contentInput {
    @apply border-4 bg-white border-white focus:border-white !important;
  }

  span > input {
    @apply text-white !important
  }

  .custom-multiselect path {
    @apply text-gray-700 !important
  }

  .custom-multiselect, .custom-multiselect *  {
    background-color: transparent !important;
    color: white;
    @apply text-sm 
  }

  .tableWrap .p-paginator-element {
    margin: 0px !important;
  }

  div .custom-multiselect, div .p-focus {
    
    @apply shadow-none hover:border-sky-600 focus:border-sky-600 border  outline-none !important;
    @apply border-gray-700
  }
  
}

@layer utilities {    

  .pi-bars {
    font-size: 28px!important;
    color: gray;
  }

  .systeminformationWrap label {
    @apply text-sm mb-2
  }

  .flex .p-password-input{
    @apply w-72 p-1 bg-transparent text-white border-gray-700
    
  }

  /* 所有捲軸樣式 */
  /* .tableWrap .p-datatable-wrapper::-webkit-scrollbar */

  .tableWrap .p-datatable-wrapper::-webkit-scrollbar,
  .overflow-y-scroll::-webkit-scrollbar,
  .overflow-scroll::-webkit-scrollbar,
  .overflow-auto::-webkit-scrollbar,
  .overflow-y-auto::-webkit-scrollbar,
  .overflow-y-scroll::-webkit-scrollbar {
    width: 6px !important;
    height: 12px !important;      
  }

  .tableWrap .p-datatable-wrapper::-webkit-scrollbar-thumb,
  .overflow-y-scroll::-webkit-scrollbar-thumb,
  .overflow-scroll::-webkit-scrollbar-thumb,
  .overflow-auto::-webkit-scrollbar-thumb,
  .overflow-y-auto::-webkit-scrollbar-thumb,
  .overflow-y-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    @apply bg-gray-700 !important;    
  }

  .tableWrap .p-datatable-scrollable-header,
  .tableWrap .p-datatable-scrollable-header-box {
    @apply m-0 !important;
  }  

  .tableWrap .p-datatable-wrapper::-webkit-scrollbar-corner,
  .overflow-y-scroll::-webkit-scrollbar-corner,
  .overflow-scroll::-webkit-scrollbar-corner,
  .overflow-auto::-webkit-scrollbar-corner,
  .overflow-y-auto::-webkit-scrollbar-corner,
  .overflow-y-scroll::-webkit-scrollbar-corner {
    @apply bg-customColor-bgGray
  }
  
   /* 在没有 hover 时隐藏滚动条 */
   /* .tableWrap .p-datatable-wrapper {
    overflow: hidden !important;
    } */
  
    /* 在 hover 时显示滚动条 */
    /* .tableWrap .p-datatable-wrapper:hover {
      overflow: scroll !important;
    } */

  /* 預設為 sticky */
  /* .tableWrap .p-datatable-thead {
    position: relative !important;
  } */

  .tableWrap .p-button {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .tableWrap td {
    @apply p-2 !important;
  }

  .menuItem > a > span {
    @apply text-white !important;
  }

  .menuItem-action > a > span {
    @apply text-yellow-300 !important;
  }

  .shareRecord .p-menuitem-link {
    @apply p-4 max-md:p-2 !important;
  }
 
  .centered-header > div > span {
    @apply text-center w-full
    
  }
 
 /* 在组件的 CSS 文件中定义动画 */
 .popup-enter {
  opacity: 0;
  transform: scale(0.9);
}
.popup-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200000ms, transform 200000ms;
}
.popup-exit {
  opacity: 1;
}
.popup-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200000ms, transform 200000ms;
}


@keyframes dialogAnime {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.dialogAnime {
  animation: dialogAnime 0.5s ease-in-out forwards;
}

@keyframes custom-target-icon {
  0% {
    transform: scale(2);
    background-color: red;    
  }

  25% {
    transform: rotate(90deg) scale(0.5); 
    background-color: yellow;   
  }

  50% {
    transform: rotate(180deg) scale(2);
    background-color: green;
  }

  75% {
    transform: rotate(270deg) scale(3.5);
    background-color: blue;
  }

  100% {
    transform: rotate(360deg) scale(2);
    background-color: purple;
  }
}

.custom-target-icon b {
  animation: custom-target-icon 0.5s infinite
}

}